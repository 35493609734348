<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 1147 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1146.14 0H0V1146.14H1146.14V0Z" fill="#D60A52" />
    <path
      d="M768.005 406.166H909.455V341.5H768.005C632.013 341.5 611.713 424.038 611.713 473.236C611.713 516.514 626.506 550.506 654.847 573.051C626.506 595.621 611.713 629.613 611.713 672.866V804.632H683.222V672.896C683.222 639.728 693.298 605.821 767.995 605.821H909.445V540.311H768.005C693.308 540.311 683.232 506.418 683.232 473.236C683.232 440.053 693.308 406.196 768.005 406.196"
      fill="white"
    />
    <path
      d="M429.553 536.051L353.331 406.167H390.64C465.342 406.167 475.418 440.059 475.418 473.207C475.418 508.698 461.558 527.996 429.553 536.011M546.932 473.207C546.932 423.994 526.637 341.47 390.64 341.47H236.671L352.606 540.267H249.195V804.633H320.704V604.973H390.228L505.437 804.633H585.691L463.629 594.346C517.559 575.972 546.917 533.677 546.917 473.246"
      fill="white"
    />
  </svg>
</template>
